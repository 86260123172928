import {
  submissionCollectionKey,
  Submission,
  ComplementedSubmission,
  ComplementedSubmissionData,
  convertToSubmissionData,
  SubmissionStatus,
  getValuesFromFileMetadata
} from "@/entities/submission";
import {
  SubmissionMaster,
  convertToSubmissionMaster
} from "@/entities/submission_master";
import { Student, convertToStudent } from "@/entities/student";
import firebase from "firebase/app";
import mime from "mime";

export async function getSubmissionOfStudent(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0
): Promise<Submission[]> {
  let query = studentRef
    .collection(submissionCollectionKey)
    .orderBy("closedAt"); // 締切日で昇順ソート
  if (limit > 0) {
    query = query.limit(limit);
  }
  const submissionsSnapshot = await query.get();
  if (submissionsSnapshot.empty) {
    return [];
  }
  return submissionsSnapshot.docs.map(doc => ({
    data: convertToSubmissionData(doc.data()),
    ref: doc.ref
  }));
}

export async function getSubmissionById(
  submissionDocIds: string[]
): Promise<Submission[]> {
  /*
    .where(field, "in", list)
    の list を 11 件以上にすることが Firebase の仕様上できないので
    10 件ずつ分割して取得する。
  */
  const chunk = [];
  for (let i = 0, l = submissionDocIds.length; i < l; i++) {
    if (i % 10 === 0) chunk.push([] as string[]);
    chunk[Math.floor(i / 10)].push(submissionDocIds[i]);
  }
  const partialResults = await Promise.all(
    chunk.map(async c => {
      const snapshot = await firebase
        .firestore()
        .collectionGroup(submissionCollectionKey)
        .where("uid", "in", c)
        .get();
      if (snapshot.empty) return [] as Submission[];
      return snapshot.docs.map(doc => {
        const submission = {
          ref: doc.ref,
          data: convertToSubmissionData(doc.data())
        };
        return submission as Submission;
      });
    })
  );
  const results = partialResults.flat();
  results.sort(
    (a, b) =>
      submissionDocIds.indexOf(a.ref.id) - submissionDocIds.indexOf(b.ref.id)
  );
  return results;
}

export async function getSubmissionBySubmissionMaster(
  submissionMasterRef: firebase.firestore.DocumentReference
): Promise<Submission[]> {
  const snapshot = await firebase
    .firestore()
    .collectionGroup(submissionCollectionKey)
    .where("submissionMasterRef", "==", submissionMasterRef)
    .get();
  if (snapshot.empty) return [] as Submission[];
  const results = snapshot.docs.map(doc => {
    const submission = {
      ref: doc.ref,
      data: convertToSubmissionData(doc.data())
    };
    return submission as Submission;
  });
  return results;
}

/*
  submission にそれぞれ対応する
  submissionMaster, student を辿って補完して返す。
*/
export async function complementSubmissions(
  submissions: Submission[]
): Promise<ComplementedSubmission[]> {
  return new Promise((resolve, reject) => {
    // 同じ submissionMaster を重複して取得しなくてよいよう、重複を省いたリストを作る
    const submissionMastersToGet = submissions
      .map(submission => submission.data.submissionMasterRef)
      .filter((ref, index, self) => {
        return self.findIndex(m => m.id === ref.id) === index;
      });
    // student についても、重複を省いたリストを作る
    const studentsToGet = submissions
      .map(submission => submission.ref.parent.parent)
      .filter((ref, index, self) => {
        return self.findIndex(m => m?.id === ref?.id) === index;
      });

    // 画像以外のファイルのメタデータを取得する
    const fileMetadataToGet = submissions.map(s => {
      return Promise.all(
        s.data.fileUrls.map(async url => {
          const ref = await firebase.storage().refFromURL(url);
          return new Promise((resolve, _) => {
            ref
              .getMetadata()
              .then(resolve)
              .catch(() => {
                // ファイルが定期処理により削除されている場合
                resolve({
                  size: -1,
                  contentType: "_deleted"
                });
              });
          });
        })
      );
    });

    Promise.all([
      // submissionMaster を取得する
      Promise.all(submissionMastersToGet.map(ref => ref.get())),
      // student を取得する
      Promise.all(studentsToGet.map(ref => ref?.get())),
      // 画像以外のファイルのメタデータを取得する
      Promise.all(fileMetadataToGet),
      submissions
    ])
      .then(results => {
        const submissionMasters = results[0];
        const students = results[1];
        const fileMetaData = results[2];
        const dataMaps = results[3].map((submission, submissionIndex) => {
          /*
              Student を整理。Submission があって Student がないことは
              通常考えられないのでビルドを通すための手続き
            */
          const studentDocId = submission.ref.parent?.parent?.id ?? "";
          const matchedStudent = students.find(
            student => student?.ref.id === studentDocId
          );
          const studentData = matchedStudent?.data();
          let student: Student | undefined;
          if (studentData && matchedStudent) {
            student = convertToStudent(studentData, matchedStudent.ref);
          }

          const matchedSubmissionMaster = submissionMasters.find(
            m => m.ref.id === submission.data.submissionMasterRef.id
          );
          const submissionMasterData = matchedSubmissionMaster?.data();
          let submissionMaster: SubmissionMaster | undefined;
          if (submissionMasterData && matchedSubmissionMaster) {
            submissionMaster = convertToSubmissionMaster(
              submissionMasterData,
              matchedSubmissionMaster.ref
            );
          }

          const ComplementedSubmissionData = {
            ...submission.data,
            fileDataList: fileMetaData[submissionIndex].map(
              (metadata: any, urlIndex: number) => {
                const values = getValuesFromFileMetadata(
                  metadata.size,
                  metadata.contentType
                );
                return {
                  url: submission.data.fileUrls[urlIndex],
                  ...values,
                  contentTypeToDisplay:
                    values.contentIconType !== "file"
                      ? values.contentTypeToDisplay
                      : `${mime.getExtension(metadata.contentType)}ファイル` ??
                        values.contentTypeToDisplay
                };
              }
            )
          };

          const result = {
            ...submission,
            ref: submission.ref,
            data: ComplementedSubmissionData as ComplementedSubmissionData,
            submissionMasterRef: submissionMaster?.ref,
            submissionMasterData: submissionMaster?.data,
            studentRef: student?.ref,
            studentData: student?.data
          } as ComplementedSubmission;
          return result;
        });
        resolve(dataMaps);
      })
      .catch(reject);
  });
}

export async function putSubmission(
  submissionRef: firebase.firestore.DocumentReference,
  options: {
    checkedAt?: number;
    status?: SubmissionStatus;
  }
): Promise<void> {
  await submissionRef.update(options);
}

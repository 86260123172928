import firebase from "firebase/app";

import { SubmissionMasterData } from "./submission_master";
import { StudentData } from "./student";

export const submissionCollectionKey = "submissions";
export const safeFileTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/heif",
  "image/heic",
  "image/heif-sequence",
  "image/heic-sequence",
  "image/webp"
];

/*
    提出物マスターデータに対応して生徒ごとに作成される
    生徒の提出物の状況を表すドキュメント。
*/
export type Submission = {
  ref: firebase.firestore.DocumentReference;
  data: SubmissionData;
};

/*
  提出物マスター、生徒を補完した状態の Submission
*/
export type ComplementedSubmission = {
  ref: firebase.firestore.DocumentReference;
  data: ComplementedSubmissionData;
  submissionMasterRef?: firebase.firestore.DocumentReference;
  submissionMasterData?: SubmissionMasterData;
  studentRef?: firebase.firestore.DocumentReference;
  studentData?: StudentData;
};
export type SubmissionStatus =
  | "submitted"
  | "unsubmitted"
  | "rejected_submitted"
  | "rejected_unsubmitted"
  | "fulfilled";

export type SubmissionData = {
  submissionMasterRef: firebase.firestore.DocumentReference;
  status: SubmissionStatus;
  submittedAt: number; // 提出された unixTime
  checkedAt: number; // 先生が確認した unixTime
  imageUrls: string[]; // 生徒の提出画像（storageのURL）の配列
  fileUrls: string[]; // 生徒の画像以外の提出ファイル（storageのURL）の配列
  submittableFrom: number; // 生徒側に表示される unixTime（submission_master と同じ値）
  closedAt: number; // 締切 unixTime（submission_master と同じ値）
  displayEndAt?: number; // 表示終了日(生徒画面で表示されなくなる日) unixtime
  isStartNotificationSent?: boolean; //表示開始日に生徒に送られたか
  comment?: string;
  uid: string;
};

export type FileData = {
  url: string;
  contentTypeToDisplay: string;
  contentIconType: string;
  sizeString: string;
};
export type ComplementedSubmissionData = {
  submissionMasterRef: firebase.firestore.DocumentReference;
  status: SubmissionStatus;
  submittedAt: number;
  checkedAt: number;
  imageUrls: string[];
  fileDataList: FileData[]; // メタデータを補完
  fileUrls: string[];
  submittableFrom: number;
  closedAt: number;
  displayEndAt?: number;
  isStartNotificationSent?: boolean; //表示開始日に生徒に送られたか
  comment?: string;
  uid: string;
};

export const convertToSubmissionData = (
  data: firebase.firestore.DocumentData
) => {
  return {
    submissionMasterRef: data.submissionMasterRef,
    status: data.status,
    submittedAt: data.submittedAt,
    checkedAt: data.checkedAt,
    imageUrls: data.imageUrls,
    fileUrls: data.fileUrls ?? [],
    submittableFrom: data.submittableFrom,
    closedAt: data.closedAt,
    displayEndAt: data.displayEndAt ?? 0,
    isStartNotificationSent: data.isStartNotificationSent ?? false,
    comment: data.comment ?? "",
    uid: data.uid
  } as SubmissionData;
};

export const convertToSubmission = (
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Submission => {
  return {
    data: convertToSubmissionData(data),
    ref
  };
};

export const submissionStatusToText = (raw: SubmissionStatus): string => {
  switch (raw) {
    case "submitted":
      return "確認待ち";
    case "unsubmitted":
      return "未提出";
    case "rejected_submitted":
      return "再 : 確認待ち";
    case "rejected_unsubmitted":
      return "再 : 未提出";
    case "fulfilled":
      return "確認済";
    default:
      return "";
  }
};

export function getValuesFromFileMetadata(
  size: number,
  contentType = ""
): {
  contentTypeToDisplay: string;
  contentIconType: string;
  sizeString: string;
} {
  let contentTypeToDisplay: string;
  let contentIconType: string;
  switch (true) {
    case contentType.includes("audio"):
      contentTypeToDisplay = "音声ファイル";
      contentIconType = "soundwave";
      break;
    case contentType.includes("video"):
      contentTypeToDisplay = "動画ファイル";
      contentIconType = "film";
      break;
    case contentType.includes("pdf"):
      contentTypeToDisplay = "PDFファイル";
      contentIconType = "pdf";
      break;
    case contentType.includes("_deleted"):
      contentTypeToDisplay = "ファイル（削除済）";
      contentIconType = "file";
      break;
    default:
      contentTypeToDisplay = "ファイル";
      contentIconType = "file";
      break;
  }
  let sizeString = "";
  if (size === -1) {
    sizeString = "";
  } else if (size / 1000 < 1000) {
    sizeString = `${Math.floor((size / 1000) * 10) / 10} KB`;
  } else {
    sizeString = `${Math.floor((size / 1000 / 1000) * 10) / 10} MB`;
  }

  return {
    contentTypeToDisplay,
    contentIconType,
    sizeString
  };
}

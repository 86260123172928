
import firebase from "firebase/app";
import "firebase/storage";
import dayjs from "dayjs";
import mime from "mime";
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { checkIsFileShouldProtected } from "@/api/submission_master";
import { postFileAsUploadTask, deleteFile } from "@/api/storage";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { RelatedLink, isExternalFileUrl } from "@/entities/submission_master";

type RelatedLinkRow = {
  label: string;
  url: string;
  labelEntered: boolean;
  urlEntered: boolean;
  isExternal: boolean;
  isDeletable: boolean; // 複数の submissionMaster, threadMaster の添付ファイルになっている場合はファイルをストレージから削除できない
};
@Options({
  components: {
    MTextField,
    MTextArea,
    MButton,
    MIcon
  },
  props: {
    class: String,
    links: Array,
    term: String,
    isDirectUrlEditorOnly: Boolean,
    limit: Number,
    masterRef: Object
  },
  emits: ["update"]
})
export default class MLinkList extends Vue {
  editingLinks: RelatedLinkRow[] = [];
  links: RelatedLink[] = [];
  class = "";
  term = "関連リンク";
  isDirectUrlEditorOnly = false;
  limit = -1;
  masterRef?: firebase.firestore.DocumentReference;

  update(value: string, field: "label" | "url", index: number) {
    const relatedLink = this.editingLinks[index];
    switch (field) {
      case "label":
        relatedLink.label = value;
        break;
      case "url":
        relatedLink.url = value;
        break;
      default:
        break;
    }
    this.$emit(
      "update",
      this.editingLinks.map(l => ({ label: l.label, url: l.url }))
    );
  }

  async deleteRelatedLink(index: number) {
    const toDelete = this.editingLinks.find((_, i) => i === index);
    if (!toDelete?.isExternal && toDelete?.isDeletable && toDelete?.url) {
      try {
        store.commit("START_LOADING", "ファイルを削除中...");
        const fileRef = firebase.storage().refFromURL(toDelete?.url);
        await deleteFile(fileRef);
        store.commit("END_LOADING");
      } catch (e) {
        store.commit("END_LOADING");
        alert(`ファイルを削除中にエラーが発生しました。\n${e.message}`);
        return;
      }
    }
    this.editingLinks = this.editingLinks.filter((_, i) => i !== index);
    this.$emit(
      "update",
      this.editingLinks.map(l => ({ label: l.label, url: l.url }))
    );
  }

  addRelatedLink() {
    this.editingLinks = [
      ...this.editingLinks,
      {
        label: "",
        url: "",
        labelEntered: false,
        urlEntered: false,
        isExternal: true,
        isDeletable: true
      }
    ];
    this.$emit(
      "update",
      this.editingLinks.map(l => ({ label: l.label, url: l.url }))
    );
  }

  async freeFormatFileUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      return;
    }
    store.commit("START_LOADING", "ファイルをアップロード中...");
    const file = event.target.files[0];
    const directory = `submissions/schools/${
      store.state.school?.ref.id ?? ""
    }/submissionMaster/`;
    const fileName = `file_${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`;
    const ext = mime.getExtension(file.type)
      ? `.${mime.getExtension(file.type)}`
      : "";
    new Promise<firebase.storage.Reference>((resolve, reject) => {
      postFileAsUploadTask(file, {
        path: `${directory}${fileName}${ext}`,
        onStateChanged: summary => {
          store.commit(
            "START_LOADING",
            `アップロード中 : ${summary.transferredPercentage} / 100 % 完了`
          );
        },
        onError: reject,
        onComplete: resolve
      });
    })
      .then(async fileRef => {
        const downloadURL = await fileRef.getDownloadURL();
        this.editingLinks = [
          ...this.editingLinks,
          {
            label: `添付資料${ext ? `（${ext}ファイル）` : ""}`,
            url: downloadURL,
            labelEntered: false,
            urlEntered: false,
            isExternal: false,
            isDeletable: true
          }
        ];
        this.$emit(
          "update",
          this.editingLinks.map(l => ({ label: l.label, url: l.url }))
        );
        store.commit("END_LOADING");
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
        store.commit("END_LOADING");
      });
  }

  mounted() {
    this.editingLinks = this.links.map((l: RelatedLink) => ({
      ...l,
      labelEntered: false,
      urlEntered: false,
      isExternal: isExternalFileUrl(l.url),
      isDeletable: !checkIsFileShouldProtected(l.url, this.masterRef)
    }));
  }
}

import {
  convertToNotification,
  Notification,
  NotificationType,
  notificationTypes,
  notificationCollectionKey
} from "@/entities/notification";
import {
  schoolCollectionKey,
  schoolConfigCollectionKey
} from "@/entities/school";
import firebase from "firebase/app";
import "firebase/firestore";
import { getSchoolConfig } from "./school";

export function getNotificationStream(
  userRef: firebase.firestore.DocumentReference,
  limit = 0,
  execTime = 0
): firebase.firestore.Query {
  let query = userRef
    .collection(notificationCollectionKey)
    .where("checked", "==", false)
    .where("timestamp", ">", execTime)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  return query;
}

function getSchoolIdFromStudentRef(
  studentRef: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >
): string {
  if (!studentRef) {
    return "";
  }
  const path = studentRef.path;
  const pathSegments = path.split("/");
  const schoolId = pathSegments[pathSegments.indexOf(schoolCollectionKey) + 1];
  return schoolId;
}

export async function sortNotificationByRoomUsage(
  notification: Notification
): Promise<Notification | null> {
  const schoolId: string = getSchoolIdFromStudentRef(notification.data.user);
  const schoolRef = firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(schoolId);

  // メッセージを送った生徒のスクールが削除され、まだそのメッセージが未読の場合にエラーがでないための対応
  const schoolConfig = await getSchoolConfig(schoolRef);
  if (schoolConfig === undefined || schoolConfig === null) {
    console.error(
      `スクールID「${schoolRef.id}」のスクールコンフィグ情報が見つかりませんでした`
    );
    return null;
  }

  return schoolConfig.data.useSchoolAiExclusively ? null : notification;
}

export async function convertAndSortNotification(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  isAdmin: boolean
): Promise<Notification | null> {
  const convertedNotification = convertToNotification(data, ref);

  if (isAdmin) {
    return await sortNotificationByRoomUsage(convertedNotification);
  }

  return convertedNotification;
}

export async function fetchNotifications(
  selectedNotificationTypes: NotificationType[],
  userRef: firebase.firestore.DocumentReference,
  limit = 0,
  isAdmin: boolean,
  offsetRef?: firebase.firestore.DocumentReference
): Promise<Notification[]> {
  let query = userRef
    .collection(notificationCollectionKey)
    .where("checked", "==", false)
    .orderBy("timestamp", "desc");
  if (limit > 0) {
    query = query.limit(limit);
  }
  if (offsetRef) {
    const offsetSnapshot = await offsetRef.get();
    query = query.startAfter(offsetSnapshot);
  }
  if (
    notificationTypes.every(type => selectedNotificationTypes.includes(type))
  ) {
    //全てのtypeがselectedNotificationTypesに含まれていた場合、全typeのメッセージをlimit件取得する
    const notificationsSnapshot = await query.get();
    if (notificationsSnapshot.empty) {
      return [];
    }
    const notificationPromises = notificationsSnapshot.docs.map(doc =>
      convertAndSortNotification(doc.data(), doc.ref, isAdmin)
    );

    return (await Promise.all(notificationPromises)).filter(
      item => item !== null
    ) as Notification[];
  } else {
    /**
     * selectedNotificationTypesの各typeのメッセージをlimit件取得する
     * 各typeのメッセージは or条件で取得する必要がある
     * 参考記事の案①をベースにFirestoreのorWhereが使えない場合の代案を実装
     * 参考: https://snamiki1212.com/summary-alt-plan-insted-of-orwhere-about-firestore
     **/
    const notificationsSnapshots = [];
    for (const type of selectedNotificationTypes) {
      notificationsSnapshots.push(await query.where("type", "==", type).get()); // 複数クエリの結果をnotificationsSnapshotsに格納
    }

    // 複数クエリの結果を1つに結合
    const notificationDocs = notificationsSnapshots
      .map(notificationsSnapshot => notificationsSnapshot.docs)
      .flat();

    const sortedLimitedMessages = notificationDocs
      .sort((a, b) => b.data().timestamp - a.data().timestamp) //複数クエリの結果をtimestampでソート
      .slice(0, limit); // limit件にする

    const sortedLimitedMessagePromises = sortedLimitedMessages.map(doc =>
      convertAndSortNotification(doc.data(), doc.ref, isAdmin)
    );
    return (await Promise.all(sortedLimitedMessagePromises)).filter(
      item => item !== null
    ) as Notification[];
  }
}

export async function checkNotification(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.update({
    checked: true
  });
}

async function getFilteredAllNotificationsLength(
  result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) {
  const userRefs: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >[] = result.docs.map(doc => doc.data().user);
  const schoolIds: string[] = userRefs.map(ref =>
    getSchoolIdFromStudentRef(ref)
  );

  const configs = await Promise.all(
    schoolIds.map(schoolId =>
      firebase
        .firestore()
        .collection(schoolConfigCollectionKey)
        .doc(schoolId)
        .get()
        .then(doc => (doc.exists ? doc.data() : null))
    )
  );

  const count = configs.reduce((acc, config) => {
    if (config && config.useSchoolAiExclusively) {
      return acc - 1;
    }
    return acc;
  }, schoolIds.length);

  return count;
}

export async function getAllNotificationsLength(
  userRef: firebase.firestore.DocumentReference,
  execTime = 0,
  isAdmin: boolean
): Promise<number> {
  const result = await userRef
    .collection(notificationCollectionKey)
    .where("checked", "==", false)
    .where("timestamp", ">", execTime)
    .orderBy("timestamp", "desc")
    .limit(100)
    .get();

  if (isAdmin) {
    return await getFilteredAllNotificationsLength(result);
  }

  return result.size;
}

export async function setNotificationForSubmissionChecked(
  submissionRef: firebase.firestore.DocumentReference
): Promise<void> {
  const req = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("set_notification_for_submission_checked");
  const res = await req({
    submissionDocId: submissionRef.id
  });
  if (res.data.error) {
    throw new Error(res.data.error);
  }
}

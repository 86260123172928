
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    active: Boolean
  },
  emits: ["showModal"]
})
export default class MTableConditionItem extends Vue {
  active = false;
  showModal = false;

  handleShowModal() {
    this.showModal = !this.showModal;
    this.$emit("showModal", this.showModal);
  }

  onEventHandler = (e: MouseEvent) => {
    // do nothing.
  };
  mounted() {
    window.addEventListener(
      "click",
      (this.onEventHandler = e => {
        if (this.$el.contains(e.target)) return; // this.$elに子要素が含まれていたら、早期リターンさせフィルターを閉じない
        this.showModal = false; //フィルターを閉じる
      })
    );
  }
  unmounted() {
    // コンポーネントが破棄されるタイミングにイベントリスナーも消す
    window.removeEventListener("click", this.onEventHandler);
  }
}


import MBaseModal from "@/components/MBaseModal.vue";
import { Options, Vue } from "vue-class-component";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MLinkList from "@/components/form/MLinkList.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import {
  convertToUnixtimeFromDateAndTime,
  getCurrentUnixtime,
  getUnixtimeAnyDaysLaterFrom
} from "@/utils/date";
import { createSubmissionMaster } from "@/api/submission_master";
import { createThreadMaster } from "@/api/thread_master";
import { Student } from "@/entities/student";
import { RelatedLink } from "@/entities/submission_master";
import store from "@/store";
import { saveErrorLog } from "@/api/error";
import dayjs from "dayjs";

@Options({
  components: {
    MBaseModal,
    MSelectBox,
    MTextField,
    MTextArea,
    MButton,
    MIcon,
    MLinkList,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    submissionMasters: Array
  }
})
export default class SubmissionMasterAddModal extends Vue {
  name = "";
  enteredName = false;
  label = "";
  enteredLabel = false;
  classroomIds: string[] = [];
  selectGroupId = "";
  submittableDate = dayjs().format("YYYY-MM-DD");
  closedDate = "";
  closedTime = "23:59";
  displayEndDate = "";
  description = "";
  relatedLinks: RelatedLink[] = [];
  selectedStudents: Student[] = [];
  willSendMessageForUnsubmitted = true;
  useManagement = false;
  submissionMasters: {
    [key: string]: string | number | boolean;
  }[] = [];

  get validData() {
    if (this.useManagement) {
      return (
        this.name !== "" &&
        this.label !== "" &&
        this.selectedStudents.length > 0 &&
        this.submittableDate &&
        this.closedAt &&
        this.closedAt > this.submittableFrom &&
        this.closedAt > this.now &&
        (this.displayEndAt === 0 || this.displayEndAt > this.closedAt) &&
        this.relatedLinks.every(link => link.label && link.url) &&
        !this.isUniqueSubmissionMasterName
      );
    } else {
      return (
        this.name !== "" &&
        this.label !== "" &&
        this.selectedStudents.length > 0 &&
        this.submittableDate &&
        (this.displayEndAt === 0 || this.displayEndAt > this.now) &&
        this.relatedLinks.every(link => link.label && link.url) &&
        !this.isUniqueSubmissionMasterName
      );
    }
  }

  get isUniqueSubmissionMasterName() {
    return this.submissionMasters.some(
      s => s.name === `${this.name}_${this.label}`
    );
  }

  get now() {
    return dayjs().unix();
  }

  get closedAt() {
    if (!this.closedDate) return 0;
    return convertToUnixtimeFromDateAndTime(this.closedDate, this.closedTime);
  }

  get displayEndAt() {
    if (!this.displayEndDate) return 0;
    return convertToUnixtimeFromDateAndTime(this.displayEndDate, "0");
  }

  get submittableFrom() {
    if (!this.submittableDate) return 0;
    return convertToUnixtimeFromDateAndTime(this.submittableDate, "0");
  }

  get students(): Student[] {
    return store.state.students;
  }

  async createSubmission() {
    if (!store.state.school) {
      return;
    }
    store.commit("START_LOADING", "作成中...");
    try {
      this.selectedStudents.forEach(async student => {
        const classroomId = student.ref.parent?.parent?.id ?? "";
        if (this.classroomIds.includes(classroomId)) return; // 同じclassroomIdが配列に存在していた場合は以下のループ処理を飛ばす
        this.classroomIds.push(classroomId);
      });
      if (this.useManagement) {
        await createSubmissionMaster(
          store.state.school.ref.id,
          this.name,
          this.label,
          this.selectedStudents.map(student => student.ref),
          this.closedAt,
          this.submittableFrom,
          this.displayEndAt,
          this.classroomIds,
          this.description,
          this.relatedLinks,
          this.willSendMessageForUnsubmitted,
          false
        );
      } else {
        await createThreadMaster(
          store.state.school.ref.id,
          this.name,
          this.label,
          this.selectedStudents.map(student => student.ref),
          this.submittableFrom,
          this.displayEndAt,
          this.classroomIds,
          this.description,
          this.relatedLinks
        );
      }

      store.commit("END_LOADING");
      this.close();
    } catch (e) {
      store.commit("END_LOADING");
      alert("作成に失敗しました");
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to create submission"
      );
      return;
    }
    this.$router.go(0);
  }

  onStudentsUpdated(students: Student[]) {
    this.selectedStudents = students;
  }

  onUpdateRelatedLinks(next: RelatedLink[]) {
    this.relatedLinks = next;
  }

  close() {
    this.$emit("close");
  }

  mounted() {
    const currentUnixtime = getCurrentUnixtime();
    this.closedDate = getUnixtimeAnyDaysLaterFrom(currentUnixtime, 7);
    this.displayEndDate = getUnixtimeAnyDaysLaterFrom(currentUnixtime, 14);
  }
}


import {
  sendImageMessageToStudents,
  sendTextMessageToStudents,
  sendMultipleMessagesToStudents
} from "@/api/message";
import {
  createMessageReservation,
  createMultipleMessagesReservation,
  createMessageRegularlyReservation,
  createMultipleMessagesRegularlyReservation
} from "@/api/message_reservation";
import { uploadImage } from "@/api/storage";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Classroom } from "@/entities/classroom";
import { Student } from "@/entities/student";
import { School, SchoolConfig } from "@/entities/school";
import { MessageTo, MessageToType } from "@/entities/message_reservation";
import { Group } from "@/entities/group";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { MessageType, MessageUser } from "@/entities/message";
import { getWeekdayStr } from "@/utils/date";
import { saveErrorLog } from "@/api/error";
import { Getter, State } from "@/store/helper";
import { selectStudentsByRoomUsage } from "@/api/student";

type RegularPatternOption = {
  id: "none" | "day" | "week" | "month";
  text: string;
};

function convertToMessageTo(
  type: MessageToType,
  groups: Group[],
  schools: School[],
  classrooms: Classroom[],
  students: Student[],
  selectedGroupId: string,
  selectSchoolId: string,
  selectClassroomId: string
): MessageTo {
  const messageTo: MessageTo = {
    type
  };
  if (type === "group") {
    const matchGroup = groups.filter(group => group.ref.id === selectedGroupId);
    if (matchGroup.length > 0) {
      messageTo.group = matchGroup[0].ref;
    } else {
      messageTo.type = "all";
    }
  } else if (type === "school") {
    const matchSchool = schools.filter(
      school => school.ref.id === selectSchoolId
    );
    if (matchSchool.length > 0) {
      messageTo.school = matchSchool[0].ref;
    } else {
      messageTo.type = "all";
    }
  } else if (type === "classroom") {
    const matchClassroom = classrooms.filter(
      classroom => classroom.ref.id === selectClassroomId
    );
    if (matchClassroom.length > 0) {
      messageTo.classroom = matchClassroom[0].ref;
    } else {
      messageTo.type = "all";
    }
  } else if (type === "students") {
    messageTo.students = students.map(student => student.ref);
  }

  return messageTo;
}

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    schools: Array,
    classrooms: Array,
    students: Array,
    groups: Array,
    defaultIsReservation: Boolean,
    defaultStudentIds: Array
  },
  watch: {
    selectSchoolId: function() {
      if (!this.selectSchoolId) return;
      const school = this.filteredSchools.filter(
        (school: School) => school.ref.id === this.selectSchoolId
      );
      if (!this.selectedSchools.includes(school[0])) {
        this.selectedSchools = [...this.selectedSchools, school[0]];
      }
    },
    selectClassroomId: function() {
      if (!this.selectClassroomId) return;
      const classroom = this.classrooms.filter(
        (classroom: Classroom) => classroom.ref.id === this.selectClassroomId
      );
      if (!this.selectedClassrooms.includes(classroom[0])) {
        this.selectedClassrooms = [...this.selectedClassrooms, classroom[0]];
      }
    }
  }
})
export default class MsSendModal extends Vue {
  selectSchoolId = "";
  schools: School[] = [];
  selectClassroomId = "";
  classrooms: Classroom[] = [];
  students: Student[] = [];
  selectedGroupId = "";
  groups: Group[] = [];
  defaultStudentIds: string[] = [];
  toType: MessageToType = "group";
  selectedStudentIds: string[] = [];
  message = "";
  files: File[] = [];
  defaultIsReservation = false;
  isReservation = false;
  reserveDay = "";
  reserveTime = "18:00";
  existDeletionTime = false;
  deletionDay = "";
  selectedRegularPattern: "none" | "day" | "week" | "month" = "none";
  selectedSchools: School[] = [];
  selectedClassrooms: Classroom[] = [];
  schoolSearchKeyword = "";
  searchedSchools: School[] = [];
  classroomSearchKeyword = "";
  searchedClassrooms: Classroom[] = [];

  @Getter("isAdmin") isAdmin!: boolean;
  @State("schoolConfigs") schoolConfigs!: SchoolConfig[];

  get types(): { type: MessageToType; text: string }[] {
    const ret: { type: MessageToType; text: string }[] = [
      {
        type: "group",
        text: "グループ"
      }
    ];
    if (this.isAdmin) {
      ret.push({
        type: "school",
        text: "スクール"
      });
    } else {
      ret.push({
        type: "classroom",
        text: "教室"
      });
    }
    return ret;
  }

  get regularPatternOptions(): RegularPatternOption[] {
    if (this.reserveDay === "") {
      return [
        {
          id: "none",
          text: "なし"
        }
      ];
    }
    const dayData = dayjs(this.reserveDay).locale("ja");
    const monthText = `毎月${dayData.date()}日`;
    const weekText = `毎週${getWeekdayStr(dayData.day())}曜日`;
    return [
      {
        id: "none",
        text: "なし"
      },
      {
        id: "month",
        text: monthText
      },
      {
        id: "week",
        text: weekText
      },
      {
        id: "day",
        text: "毎日"
      }
    ];
  }

  get modalTitle(): string {
    return this.isReservation ? "メッセージ予約" : "一斉メッセージ送信";
  }

  get mainButtonTitle(): string {
    return this.isReservation ? "送信予約" : "一斉送信";
  }

  get selectedStudents(): Student[] {
    return this.students.filter(student =>
      this.selectedStudentIds.includes(student.ref.id)
    );
  }

  get selectClassroom(): Classroom | null {
    const matchClassrooms = this.classrooms.filter(
      item => item.ref.id === this.selectClassroomId
    );
    if (matchClassrooms.length === 0) {
      return null;
    }
    return matchClassrooms[0];
  }

  get fileUrls(): string[] {
    return this.files.map(file => URL.createObjectURL(file));
  }

  get filteredStudents(): Student[] {
    let mySchoolId = "";
    if (store.state.school) {
      mySchoolId = store.state.school.ref.id; // 自分のスクールID
    }
    // カスタム公式LINEを利用するスクールのIDを取得
    const customOfficialLineSchoolIds = this.schoolConfigs
      .filter(
        schoolConfig =>
          schoolConfig.data.allowUseOfCustomOfficialLine && // カスタム公式LINEを利用するスクール
          schoolConfig.ref.id !== mySchoolId // 自分のスクールは除外
      )
      .map(schoolConfig => {
        return schoolConfig.ref.id;
      });

    const baseStudents = this.students.filter(
      student =>
        (!student.data.recessTime || student.data.recessTime <= 0) &&
        // カスタム公式LINEを利用するスクールの生徒は対象外
        !customOfficialLineSchoolIds.includes(
          student.ref.parent.parent!.parent.parent!.id
        )
    );

    if (this.toType === "group") {
      const matchGroups = this.groups.filter(
        group => group.ref.id === this.selectedGroupId
      );
      if (matchGroups.length > 0) {
        const groupStudentIds = matchGroups[0].data.result.map(item => item.id);
        return baseStudents.filter(student =>
          groupStudentIds.includes(student.ref.id)
        );
      } else {
        return baseStudents;
      }
    } else if (this.toType === "school") {
      if (this.selectedSchools.length > 0) {
        const selectedSchoolIds = this.selectedSchools.map((school: School) => {
          return school.ref.id;
        });
        return baseStudents.filter(item =>
          selectedSchoolIds.includes(item.ref.parent.parent!.parent.parent!.id)
        );
      } else {
        return baseStudents;
      }
    } else if (this.toType === "classroom") {
      if (this.selectedClassrooms.length > 0) {
        const selectedClassroomIds = this.selectedClassrooms.map(
          (classroom: Classroom) => {
            return classroom.ref.id;
          }
        );
        return baseStudents.filter(item =>
          selectedClassroomIds.includes(item.ref.parent.parent!.id)
        );
      } else {
        return baseStudents;
      }
    } else if (this.toType === "students") {
      return this.selectedStudents.filter(
        student => !student.data.recessTime || student.data.recessTime <= 0
      );
    } else {
      return baseStudents;
    }
  }

  get validMessage() {
    return this.message.length > 0 && this.message.length <= 4500;
  }

  get validReserveDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.reserveDay);
  }

  get validReserveTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.reserveTime);
  }

  get validDeletionDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.deletionDay);
  }

  get validReserve() {
    if (!this.isReservation) {
      return true;
    }

    if (this.existDeletionTime) {
      return (
        this.validReserveDay && this.validReserveTime && this.validDeletionDay
      );
    } else {
      return this.validReserveDay && this.validReserveTime;
    }
  }

  get validData() {
    return (
      this.validMessage &&
      this.filteredStudents.length > 0 &&
      this.validReserve &&
      this.validType
    );
  }

  get validType() {
    if (this.toType === "group") {
      return true;
    } else if (this.toType === "students") {
      return this.filteredStudents.length > 0;
    } else {
      return (
        this.selectedClassrooms.length > 0 || this.selectedSchools.length > 0
      );
    }
  }

  get toOnlyRoomUsers(): boolean {
    return this.isAdmin;
  }

  get filteredSchools(): School[] {
    let customeLineSchoolIds: string[] = [];
    if (store.state.role && store.state.role.data.type === "admin") {
      customeLineSchoolIds = this.schoolConfigs
        .filter(schoolConfig => schoolConfig.data.allowUseOfCustomOfficialLine)
        .map(schoolConfig => schoolConfig.ref.id);
      return this.schools.filter(
        school => !customeLineSchoolIds.includes(school.ref.id)
      );
    } else {
      return this.schools;
    }
  }

  searchSchools() {
    if (this.schoolSearchKeyword === "") {
      this.searchedSchools = [];
      return;
    }
    this.searchedSchools = this.filteredSchools.filter(
      student =>
        student.data.id.includes(this.schoolSearchKeyword) ||
        student.data.name
          .toLowerCase()
          .includes(this.schoolSearchKeyword.toLowerCase())
    );
  }

  addSchool(id: string) {
    this.selectSchoolId = id;
  }

  searchClassrooms() {
    if (this.classroomSearchKeyword === "") {
      this.searchedClassrooms = [];
      return;
    }
    this.searchedClassrooms = this.classrooms.filter(
      classroom =>
        classroom.data.id.includes(this.classroomSearchKeyword) ||
        classroom.data.name
          .toLowerCase()
          .includes(this.classroomSearchKeyword.toLowerCase())
    );
  }

  addClassroom(id: string) {
    this.selectClassroomId = id;
  }

  removeStudent(id: string) {
    this.selectedStudentIds = this.selectedStudentIds.filter(
      item => item !== id
    );
  }

  removeSchool(id: string) {
    this.selectedSchools = this.selectedSchools.filter(
      item => item.ref.id !== id
    );
    if (this.selectedSchools.length === 0) {
      this.selectSchoolId = "";
    }
  }
  removeAllSchools() {
    this.selectSchoolId = "";
    this.selectedSchools = [];
  }
  selectAllSchools() {
    this.selectedSchools = this.filteredSchools;
  }

  removeClassroom(id: string) {
    this.selectedClassrooms = this.selectedClassrooms.filter(
      item => item.ref.id !== id
    );
    if (this.selectedClassrooms.length === 0) {
      this.selectClassroomId = "";
    }
  }
  removeAllClassrooms() {
    this.selectClassroomId = "";
    this.selectedClassrooms = [];
  }
  selectAllClassrooms() {
    this.selectedClassrooms = this.classrooms;
  }

  imageUpload(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let index = 0;
    while (index < event.target.files.length) {
      if (this.files.length == 3) {
        alert("同時に送信できる画像は3個までです");
        break;
      }
      const item = event.target.files.item(index);
      if (item) {
        if (item.size > 750000) {
          alert("750KB以上のファイルサイズの画像は送信できません");
        } else {
          this.files.push(item);
        }
      }
      index++;
    }
  }

  removeImage(index: number) {
    const file = this.files[index];
    this.files = this.files.filter(item => item.name !== file.name);
  }

  async tapMainButton() {
    if (!this.validData || !store.state.role) {
      return;
    }

    if (this.isReservation) {
      store.commit("START_LOADING", "メッセージ送信予約登録中...");
    } else {
      store.commit("START_LOADING", "メッセージ送信中...");
    }

    let students: Student[] = [];
    if (this.toOnlyRoomUsers) {
      try {
        students = await selectStudentsByRoomUsage(this.filteredStudents);
      } catch (e) {
        store.commit("END_LOADING");
        alert(`送信対象の生徒情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to fetch students"
        );
        return;
      }
    } else {
      students = [...this.filteredStudents];
    }

    if (students.length === 0) {
      store.commit("END_LOADING");
      alert("送信対象に該当する生徒がいません");
      return;
    }

    let imagePathes: string[];
    try {
      imagePathes = await Promise.all(
        this.files.map(file => uploadImage(file, students[0].ref.id))
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(
        `画像の送信に失敗しました。時間を置いてから再度送信してみてください\n\n${e}`
      );
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to upload image"
      );
      return;
    }

    this.files = [];

    let fromName: string;
    if (store.state.role.data.type === "tutor" && store.state.tutor) {
      fromName = store.state.tutor.main.data.name;
    } else if (store.state.role.data.type === "admin" && store.state.admin) {
      fromName = store.state.admin.name;
    } else {
      return;
    }
    const messageUser: MessageUser = {
      type: store.state.role.data.type,
      userId: store.state.role.ref.id,
      name: fromName
    };

    const messageContents: { text: string; type: MessageType }[] = [];
    if (this.message.length > 0) {
      messageContents.push({
        text: this.message,
        type: "text"
      });
      this.message = "";
    }
    imagePathes.forEach(path => {
      if (path) {
        messageContents.push({
          text: path,
          type: "image"
        });
      }
    });

    if (this.isReservation) {
      await this.registerReservation(
        messageContents,
        messageUser,
        this.toOnlyRoomUsers
      );
    } else {
      await this.sendMessage(messageContents, messageUser, students);
    }
  }

  async registerReservation(
    messageContents: { text: string; type: MessageType }[],
    messageUser: MessageUser,
    toOnlyRoomUsers: boolean
  ) {
    store.commit("START_LOADING", "メッセージ送信予約登録中...");

    if (this.selectedSchools.length > 0) {
      for (const selectedSchool of this.selectedSchools) {
        const messageTo = convertToMessageTo(
          this.toType,
          this.groups,
          this.filteredSchools,
          this.classrooms,
          this.selectedStudents,
          this.selectedGroupId,
          selectedSchool.ref.id,
          this.selectClassroomId
        );
        await this.sendMessageReservation(
          messageTo,
          messageContents,
          messageUser,
          toOnlyRoomUsers
        );
      }
    } else if (this.selectedClassrooms.length > 0) {
      for (const selectedClassroom of this.selectedClassrooms) {
        const messageTo = convertToMessageTo(
          this.toType,
          this.groups,
          this.filteredSchools,
          this.classrooms,
          this.selectedStudents,
          this.selectedGroupId,
          this.selectSchoolId,
          selectedClassroom.ref.id
        );
        await this.sendMessageReservation(
          messageTo,
          messageContents,
          messageUser,
          toOnlyRoomUsers
        );
      }
    } else {
      const messageTo = convertToMessageTo(
        this.toType,
        this.groups,
        this.filteredSchools,
        this.classrooms,
        this.selectedStudents,
        this.selectedGroupId,
        this.selectSchoolId,
        this.selectClassroomId
      );
      await this.sendMessageReservation(
        messageTo,
        messageContents,
        messageUser,
        toOnlyRoomUsers
      );
    }

    store.commit("END_LOADING");

    alert("メッセージの送信予約が完了しました");
    this.$router.go(0);
  }

  async sendMessageReservation(
    messageTo: MessageTo,
    messageContents: { text: string; type: MessageType }[],
    messageUser: MessageUser,
    toOnlyRoomUsers: boolean
  ) {
    const reservationDateData = dayjs(
      `${this.reserveDay} ${this.reserveTime}`
    ).locale("ja");
    const reservationTime = reservationDateData.unix();

    const schoolId = store.state.school ? store.state.school.ref.id : undefined;
    const schoolName = store.state.school
      ? store.state.school.data.name
      : undefined;

    if (this.selectedRegularPattern === "none") {
      try {
        if (messageContents.length > 1) {
          // 送信対象が複数あった場合
          await createMultipleMessagesReservation(
            messageUser,
            messageTo,
            "multiple",
            messageContents,
            reservationTime,
            schoolId,
            schoolName,
            toOnlyRoomUsers
          );
        } else {
          await Promise.all(
            messageContents.map(content =>
              createMessageReservation(
                messageUser,
                messageTo,
                content.type,
                content.text,
                reservationTime,
                schoolId,
                schoolName,
                toOnlyRoomUsers
              )
            )
          );
        }
      } catch (e) {
        store.commit("END_LOADING");
        alert(e);
        if (messageContents.length > 0 && messageContents[0].type === "text") {
          this.message = messageContents[0].text;
        }
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to create message reservation"
        );
        return;
      }
    } else {
      const deletionTime = this.existDeletionTime
        ? dayjs(this.deletionDay)
            .locale("ja")
            .add(1, "day")
            .unix()
        : undefined;
      try {
        if (messageContents.length > 1) {
          // 送信対象が複数あった場合
          await createMultipleMessagesRegularlyReservation(
            messageUser,
            messageTo,
            "multiple",
            messageContents,
            reservationTime,
            this.selectedRegularPattern as "day" | "week" | "month",
            this.reserveTime,
            reservationDateData.day(),
            reservationDateData.date(),
            deletionTime,
            schoolId,
            schoolName,
            toOnlyRoomUsers
          );
        } else {
          await Promise.all(
            messageContents.map(content =>
              createMessageRegularlyReservation(
                messageUser,
                messageTo,
                content.type,
                content.text,
                reservationTime,
                this.selectedRegularPattern as "day" | "week" | "month",
                this.reserveTime,
                reservationDateData.day(),
                reservationDateData.date(),
                deletionTime,
                schoolId,
                schoolName,
                toOnlyRoomUsers
              )
            )
          );
        }
      } catch (e) {
        store.commit("END_LOADING");
        alert(e);
        if (messageContents.length > 0 && messageContents[0].type === "text") {
          this.message = messageContents[0].text;
        }
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to create message regularly reservation"
        );
        return;
      }
    }
  }
  async sendMessage(
    messageContents: { text: string; type: MessageType }[],
    messageUser: MessageUser,
    students: Student[]
  ) {
    try {
      const futures: Promise<void>[] = [];
      if (messageContents.length > 1) {
        // 送信対象が複数あった場合
        futures.push(
          sendMultipleMessagesToStudents(
            students.map(student => student.ref),
            store.state.school ? store.state.school.data.name : "",
            messageUser,
            messageContents
          )
        );
      } else {
        messageContents.forEach(content => {
          if (content.type === "text") {
            futures.push(
              sendTextMessageToStudents(
                students.map(student => student.ref),
                store.state.school ? store.state.school.data.name : "",
                messageUser,
                content.text
              )
            );
          } else if (content.type === "image") {
            futures.push(
              sendImageMessageToStudents(
                students.map(student => student.ref),
                messageUser,
                content.text
              )
            );
          }
        });
      }
      await Promise.all(futures);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      if (messageContents.length > 0 && messageContents[0].type === "text") {
        this.message = messageContents[0].text;
      }
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to send messages"
      );
      return;
    }

    store.commit("END_LOADING");

    alert("メッセージの一斉送信が完了しました");
    this.$router.go(0);
  }

  created() {
    if (this.defaultStudentIds.length > 0) {
      this.toType = "students";
      this.selectedStudentIds = this.defaultStudentIds;
    }
    if (this.defaultIsReservation) {
      this.isReservation = true;
    }
    this.reserveDay = dayjs()
      .locale("ja")
      .add(1, "day")
      .format("YYYY-MM-DD");
  }

  close() {
    this.$emit("close");
  }
}


import store from "@/store";
import { Getter } from "@/store/helper";
import { School } from "@/entities/school";
import { Group } from "@/entities/group";
import { Classroom } from "@/entities/classroom";
import { Student, getGradeIndex } from "@/entities/student";
import { MessageUserType } from "@/entities/message";
import { MessageToType } from "@/entities/message_reservation";
import {
  getSubmissionBySubmissionMaster,
  complementSubmissions,
  putSubmission
} from "@/api/submission";
import { setNotificationForSubmissionChecked } from "@/api/notification";
import { getSubmissionMasterByClassroomIds } from "@/api/submission_master";
import { getSchoolDetail } from "@/api/school";
import { createMessageReservation } from "@/api/message_reservation";
import { sendTextMessage } from "@/api/message";
import {
  ComplementedSubmission,
  submissionStatusToText
} from "@/entities/submission";
import { SubmissionMaster } from "@/entities/submission_master";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { Breadcrumb } from "@/components/MHeaderPanel.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import SubmissionMasterDetailMultiRunDropDown from "@/components/submission/SubmissionMasterDetailMultiRunDropDown.vue";
import { Options, Vue } from "vue-class-component";
import { submissionMasterDetailDefinitions } from "@/utils/table";
import {
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "@/utils/date";
import SubmissionMasterAddModal from "@/components/submission/SubmissionMasterAddModal.vue";
import { State } from "@/store/helper";
import dayjs from "dayjs";

type StatusItem = {
  id: string;
  name: string;
  checked: boolean;
};

type SubmissionRow = {
  id: string;
  name: string;
  "suf-name": string;
  grade: string;
  "pre-grade": number;
  status: string;
  "pre-status": number;
  submittedAtDate: string;
  submittedAtTime: string;
  checkedAt: string;
  link: string;
  relatedLink: string;
};

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MTableConditionItem,
    MNavBar,
    MHeaderPanel,
    MsSendModal,
    SubmissionMasterDetailMultiRunDropDown,
    SubmissionMasterAddModal
  }
})
export default class SubmissionMasterList extends Vue {
  definitions = submissionMasterDetailDefinitions;
  schoolId = "";
  complementedSubmissions: ComplementedSubmission[] = [];
  submissionMaster: SubmissionMaster | null = null;
  breadcrumbs: Breadcrumb[] = [
    {
      text: "提出BOX",
      link: "/submission_master"
    }
  ];
  selectedIds: string[] = [];
  selectedColumnList: number[] = [];
  statusList: StatusItem[] = [
    {
      id: "unsubmitted",
      name: submissionStatusToText("unsubmitted"),
      checked: true
    },
    {
      id: "submitted",
      name: submissionStatusToText("submitted"),
      checked: true
    },
    {
      id: "rejected_submitted",
      name: submissionStatusToText("rejected_submitted"),
      checked: true
    },
    {
      id: "rejected_unsubmitted",
      name: submissionStatusToText("rejected_unsubmitted"),
      checked: true
    },
    {
      id: "fulfilled",
      name: submissionStatusToText("fulfilled"),
      checked: true
    }
  ];
  isSendModalDisplayed = false;
  searchKeyword = "";
  initialized = false;

  @State("schools") schools!: School[];
  @State("classrooms") classrooms!: Classroom[];
  @State("students") students!: Student[];
  @State("groups") groups!: Group[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get pageTitle() {
    return this.submissionMaster?.data.name ?? "";
  }
  get filterIsSelected() {
    return false;
  }

  get columnList() {
    return this.definitions.map((value, index) => ({
      id: index,
      value: value.text
    }));
  }

  toggleStatusChecked(id: string) {
    this.statusList = this.statusList.map(status => {
      if (status.id !== id) return status;
      return { ...status, checked: !status.checked };
    });
  }

  toggleAllStatus(checked: boolean) {
    this.statusList = this.statusList.map(status => {
      return { ...status, checked };
    });
  }

  selectAllColumn() {
    this.selectedColumnList = [...Array(this.columnList.length)].map(
      (_, i) => i
    );
  }

  deselectAllColumn() {
    this.selectedColumnList = [];
  }

  get filteredDatas(): SubmissionRow[] {
    let results: SubmissionRow[] = this.complementedSubmissions.map(s => {
      const submittedAtDate =
        s.data.submittedAt > 0
          ? convertToDateFromUnixtimeSlash(s.data.submittedAt)
          : "--";
      const submittedAtTime =
        s.data.submittedAt > 0
          ? convertToTimeFromUnixtime(s.data.submittedAt)
          : "--";
      const checkedAt =
        s.data.checkedAt > 0
          ? convertToDateFromUnixtimeSlash(s.data.checkedAt)
          : "--";
      const status = submissionStatusToText(s.data.status);
      const preGrade = getGradeIndex(s.studentData?.grade ?? "");
      const preStatus = [
        "unsubmitted",
        "submitted",
        "rejected_unsubmitted",
        "rejected_submitted",
        "fulfilled"
      ].indexOf(s.data.status);
      const studentsClassroom = s.studentRef?.parent.parent;
      const studentsSchool =
        studentsClassroom && studentsClassroom.parent.parent;
      return {
        id: s.ref.id,
        name: s.studentData?.name ?? "",
        "suf-name": `${this.schoolId}-${s.studentData?.id}`,
        grade: s.studentData?.grade,
        "pre-grade": preGrade,
        status,
        "pre-status": preStatus,
        submittedAtDate,
        submittedAtTime,
        checkedAt,
        link: `/student/${studentsSchool?.id ?? ""}/${studentsClassroom?.id ??
          ""}/${s.studentRef?.id ?? ""}/submissions`,
        relatedLink: `/submission/print?id=${s.ref.id}`,
        comment: s.data.comment || "--"
      } as SubmissionRow;
    });
    if (this.searchKeyword.length > 0) {
      results = results.filter(r =>
        r.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
      );
    }
    let filteredResults = [] as SubmissionRow[];
    results.forEach(r => {
      const status = this.statusList.find(s => s.name === r.status);
      if (status?.checked) filteredResults = [...filteredResults, r];
    });
    return filteredResults;
  }

  get coloredRowList() {
    const fulfilled = this.filteredDatas
      .filter(e => e.status === "確認済")
      .map(e => ({ key: "name", data: e.name, color: "#d5d5d5" }));
    const unSubmitted = this.filteredDatas
      .filter(e => e.status === "未提出" || e.status === "再 : 未提出")
      .map(e => ({ key: "name", data: e.name, color: "#f7b8b2" }));
    return [...fulfilled, ...unSubmitted];
  }

  changeSelectedId(id: string) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(item => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  get selectedStudentId(): string[] {
    return this.selectedIds
      .map(submissionId => {
        const matched = this.complementedSubmissions.find(
          _ => _.ref.id === submissionId
        );
        return matched?.studentRef?.id ?? "";
      })
      .filter(_ => _);
  }

  async setAllFulfilled() {
    if (store.state.loading) return;
    store.commit("START_LOADING", "処理中…");
    try {
      // メッセージ送信のため生徒共通で必要な値をあらかじめ取得
      const now = dayjs();
      const isInCoreTime = now.hour() >= 7 && now.hour() <= 21;
      const [school] = await getSchoolDetail(
        false,
        store.state.school?.ref.id ?? ""
      );
      if (!school) throw new Error("school not found");
      const schoolName = school.data.name;

      let fromName = "";
      if (this.isTutorOfSchool) {
        fromName = store.state.tutor?.main.data.name ?? "";
      } else if (this.isAdmin) {
        fromName = store.state.admin?.name ?? "";
      } else {
        throw new Error("user not found");
      }

      await Promise.all(
        this.selectedIds.map(id => {
          const complemented = this.complementedSubmissions.find(
            _ => _.ref.id === id
          );
          if (!complemented || !complemented.studentRef) return;
          const promises = [];
          const currentStatus = complemented.data.status;
          if (
            currentStatus === "submitted" ||
            currentStatus === "rejected_submitted"
          ) {
            /*
              未提出 → 確認済に変えるのは先生の管理上の都合によると思われるので
              メッセージを送るのは提出済だった場合のみにする。
            */
            // 塾名の取得
            const messageFrom = {
              type: "tutor" as MessageUserType,
              userId: store.state.role?.ref.id ?? "",
              name: fromName
            };

            const messageText = `🙆‍♂️「${complemented.submissionMasterData
              ?.name ?? ""}」を確認しました。おつかれさま😄`;

            if (isInCoreTime) {
              // 7:00 ~ 21:59 の間は即座に生徒にメッセージを送信する
              promises.push(
                sendTextMessage(
                  complemented.studentRef,
                  schoolName,
                  messageFrom,
                  messageText
                )
              );
            } else {
              // 夜間早朝は次の 7:00 に送信されるメッセージを予約する
              const messageTo = {
                type: "students" as MessageToType,
                students: [complemented.studentRef]
              };
              const reservationTime = dayjs()
                .add(now.hour() > 21 ? 1 : 0, "day")
                .hour(7)
                .minute(0)
                .second(0)
                .unix();
              promises.push(
                createMessageReservation(
                  messageFrom,
                  messageTo,
                  "text",
                  messageText,
                  reservationTime,
                  school.ref.id,
                  schoolName
                )
              );
            }
          }

          // submission を更新
          promises.push(
            putSubmission(complemented.ref, {
              checkedAt: now.unix(),
              status: "fulfilled"
            })
          );
          // 対応する通知メッセージを既読にする
          promises.push(setNotificationForSubmissionChecked(complemented.ref));

          return Promise.all(promises);
        })
      );

      // 画面を更新
      await this.getSubmissions();
      alert(
        isInCoreTime
          ? "選択した生徒の提出物を確認済にしました。確認待ちだった生徒にはメッセージを送信しました。"
          : "選択した生徒の提出物を確認済にしました。確認待ちだった生徒には朝 7 時にメッセージが送信されます。"
      );
    } catch (e) {
      alert(`更新処理中にエラーが発生しました。\n${e.message}`);
    } finally {
      store.commit("END_LOADING");
    }
  }

  openAll() {
    window.open(`/submission/print?id=${this.selectedIds.join(",")}`, "_blank");
  }

  toggleSendModal(isDisplayed: boolean) {
    this.isSendModalDisplayed = isDisplayed;
  }

  async refresh() {
    if (store.state.loading) return;
    store.commit("START_LOADING", "更新中…");
    await this.getSubmissions();
    store.commit("END_LOADING");
  }

  async getSubmissions() {
    if (!this.submissionMaster) return;
    const submissions = await getSubmissionBySubmissionMaster(
      this.submissionMaster.ref
    );
    // submission に student, submissionMaster を補完
    const complemented = await complementSubmissions(submissions);
    this.complementedSubmissions = complemented as ComplementedSubmission[];
  }

  async mounted() {
    this.selectAllColumn();
    this.schoolId = store.state.school?.data.id as string; // uid ではなく、ログイン時に使う数字
    try {
      // 対応する SubmissionMaster を取得
      const schoolDocId: string = store.state.school?.ref.id ?? "";
      if (!schoolDocId) throw new Error("school data not found"); // 通常ありえない
      const classroomIds = store.state.tutor?.config.data.classrooms.map(
        classroom => classroom.id
      ) as string[];
      const submissionMasters = await getSubmissionMasterByClassroomIds(
        schoolDocId,
        classroomIds
      );
      const submissionMasterId = this.$route.params
        .submissionMasterId as string;
      const matchedSubmissionMaster = submissionMasters.find(
        s => s.ref.id === submissionMasterId
      );
      if (!matchedSubmissionMaster) {
        // classroom 単位の権限がない SubmissionMaster にアクセスしようとしている場合は除外される
        throw new Error("cannot access submission master");
      }
      this.submissionMaster = matchedSubmissionMaster;
      this.getSubmissions();
    } catch (e) {
      alert(`提出物が取得できませんでした。\n${e.message}`);
    } finally {
      this.initialized = true;
    }
  }
}


import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Getter } from "@/store/helper";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MIcon
  },
  emits: ["setAllFulfilled", "openAll", "message"],
  props: {
    disabled: Boolean,
    useManagement: Boolean
  }
})
export default class MTableConditionItem extends Vue {
  disabled = false;
  useManagement = true;
  showModal = false;

  @Getter("isAdmin") isAdmin!: boolean;

  changeShowModal() {
    if (this.disabled) {
      return;
    }
    this.showModal = !this.showModal;
  }

  setAllFulfilled() {
    this.showModal = false;
    this.$emit("setAllFulfilled");
  }

  openAll() {
    this.showModal = false;
    this.$emit("openAll");
  }

  message() {
    this.showModal = false;
    this.$emit("message");
  }
}

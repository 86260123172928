import firebase from "firebase/app";

export const notificationCollectionKey = "notifications";

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

const _notificationTypes = [
  "get_message",
  "get_image_message",
  "enter_room",
  "leave_room",
  "start_timer",
  "end_timer",
  "post_reflection",
  "post_reflection_of_timer",
  "announce",
  "post_submission",
  "obtain_badge",
  "other"
] as const;

export type NotificationType = ElementType<typeof _notificationTypes>;

export const notificationTypes = [..._notificationTypes] as NotificationType[];
export type Notification = {
  ref: firebase.firestore.DocumentReference;
  data: NotificationData;
};

export type NotificationData = {
  title: string;
  content: string;
  type: NotificationType;
  user: firebase.firestore.DocumentReference;
  checked: boolean;
  timestamp: number;
  submissionRef?: firebase.firestore.DocumentReference;
  threadRef?: firebase.firestore.DocumentReference;
  learningRef?: firebase.firestore.DocumentReference;
};

export function convertToNotification(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Notification {
  const returnData: NotificationData = {
    title: data.title,
    content: data.content,
    type: data.type,
    user: data.user,
    checked: data.checked,
    timestamp: data.timestamp,
    submissionRef: data.submissionRef,
    threadRef: data.threadRef,
    learningRef: data.learningRef
  };

  return {
    ref,
    data: returnData
  };
}
